<template>
  <div class="containerView">
    <router-view />
  </div>
  <footer class="footer">
    <div class="router-footer">
      <router-link
        class="mr-[20px] hover:text-yellow-400"
        :to="`/${channel}/privacy-policy`"
        >Privacy Policy</router-link
      >
      <router-link
        :to="`/${channel}/terms-of-service`"
        class="mr-[20px] hover:text-yellow-400"
        >Terms of Service</router-link
      >
      <router-link :to="`/${channel}/contact-us`" class="hover:text-yellow-400"
        >Contact Us</router-link
      >
    </div>
    <p>© 2024 NovaBeats Video.</p>
  </footer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const channel = ref("");
onMounted(() => {
  setTimeout(() => {
    channel.value = route.params?.channel;
  }, 1000);
});
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
.containerView {
  @apply md:w-[1080px] md:min-w-[1080px] md:m-auto;
}
.footer {
  @apply bg-gray-200 text-gray-700 text-[.32rem] py-2 text-center mt-2 md:text-[14px] md:py-[15px];
}
.router-footer {
  @apply mb-[10px];
}
</style>
