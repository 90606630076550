import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const urlList = [
  "media.nova-beats.com",
  "media.novabeatsapp.com",
  "media.nova-beats.net",
  "media.nova-beatsapp.com",
  "media.novabeatsvip.com",
];
const appUrl2 = window.location.href;

let firebaseConfig = {
  apiKey: "AIzaSyCO1BMFaAVBichokkh0RhkPZbhnGduSSvY",
  authDomain: "shortvideo-4bd83.firebaseapp.com",
  projectId: "shortvideo-4bd83",
  storageBucket: "shortvideo-4bd83.firebasestorage.app",
  messagingSenderId: "1090936506539",
  appId: "1:1090936506539:web:6ed279877a6d479515cfee",
  measurementId: "",
};


function getHrefUrl2(url) {
  let urlData = "";
  urlList.forEach((item) => {
    if (url.indexOf(item) > -1) {
      urlData = item;
    }
  });
  return urlData;
}

// eslint-disable-next-line no-undef
switch (getHrefUrl2(appUrl2)) {
  case "media.nova-beats.com":
    firebaseConfig.measurementId = "G-F7KDK0F2RY";
    firebaseConfig.appId = "1:1090936506539:web:6ed279877a6d479515cfee"
    break;
  case "media.novabeatsapp.com":
    firebaseConfig.measurementId = "G-67XCNVQD6Z";
    firebaseConfig.appId = "1:1090936506539:web:d08e3bfb8d1263f515cfee"
    break;
  case "media.nova-beats.net":
    firebaseConfig.measurementId = "G-9J477ZBQQD";
    firebaseConfig.appId = "1:1090936506539:web:ed7af8fda33b484215cfee"
    break;
  case "media.nova-beatsapp.com":
    firebaseConfig.measurementId = "G-SQDEWP49WK";
    firebaseConfig.appId = "1:1090936506539:web:86e0278e038711b415cfee"
    break;
  case "media.novabeatsvip.com":
    firebaseConfig.measurementId = "G-T84FYRLBDD";
    firebaseConfig.appId = "1:1090936506539:web:4b0ef656dea6e8d115cfee"
    break;
}
console.log(firebaseConfig);
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default {
  app,
  analytics,
};
